import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import EmailSignup from "../../../MailChimp/EmailSignup";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "master-python-pandas-with-short-easy-to-read-code-snippets",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#master-python-pandas-with-short-easy-to-read-code-snippets",
        "aria-label": "master python pandas with short easy to read code snippets permalink",
        "className": "anchor before",
        "parentName": "h1"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Master Python pandas with short, easy-to-read code snippets`}</h1>
    <p><a {...{
        "href": "/",
        "parentName": "p"
      }}>{`Use Pandas`}</a>
      {` is a collection of easy to use Python pandas recipes made by `}
      <a {...{
        "href": "https://twitter.com/KennethCassel",
        "parentName": "p"
      }}>{`Kenneth Cassel`}</a>
      {`.`}</p>
    <p>{`This site is filled with easy-to-read short recipes that will help you become a pandas guru in no time.`}</p>

    <br />
    <h2 {...{
      "id": "panda-recipes",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#panda-recipes",
        "aria-label": "panda recipes permalink",
        "className": "anchor before",
        "parentName": "h2"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Panda recipes...?`}</h2>
    <p>{`Not panda recipes 🐼..`}</p>
    <p><em {...{
        "parentName": "p"
      }}>{`pandas`}</em>
      {` recipes. This kind --> `}
      <a {...{
        "href": "https://pandas.pydata.org/docs/getting_started/overview.html",
        "parentName": "p"
      }}>{`pandas`}</a></p>
    <p>{`pandas is an open-source Python package for `}
      <em {...{
        "parentName": "p"
      }}>{`data analysis`}</em>
      {` and manipulation.`}</p>
    <p>{`It is hugely popular with Data Scientists, Data analysists, Data Engineers, Machine Learning Engineers, and more.
It's a fantastic project and one of my favorite go-to packages.`}</p>
    <EmailSignup mdxType="EmailSignup" />
    <hr />
    <h2 {...{
      "id": "how-to-support-the-use-pandas-project",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#how-to-support-the-use-pandas-project",
        "aria-label": "how to support the use pandas project permalink",
        "className": "anchor before",
        "parentName": "h2"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`How to support the Use Pandas project?`}</h2>
    <p>{`There's a couple ways!`}</p>
    <h3 {...{
      "id": "star-use-pandas-in-github-️",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#star-use-pandas-in-github-%EF%B8%8F",
        "aria-label": "star use pandas in github ️ permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Star Use Pandas in Github ⭐️`}</h3>
    <p>{`Click this link to the usepandas project and give it a star! `}
      <a {...{
        "href": "https://github.com/kennethcassel/usepandas",
        "parentName": "p"
      }}>{`Use Pandas github repo`}</a></p>
    <h3 {...{
      "id": "create-a-pull-request",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#create-a-pull-request",
        "aria-label": "create a pull request permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Create a pull request`}</h3>
    <p>{`If you find anything wrong with this site or that you'd feel like improving, a PR would be a great help.`}</p>
    <p>{`You can submit a PR `}
      <a {...{
        "href": "https://github.com/kennethcassel/usepandas",
        "parentName": "p"
      }}>{`here`}</a></p>
    <h3 {...{
      "id": "support-the-pandas-package-itself",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#support-the-pandas-package-itself",
        "aria-label": "support the pandas package itself permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Support the pandas package itself`}</h3>
    <p>{`If you Use Pandas regularly, consider supporting the project by `}
      <a {...{
        "href": "https://pandas.pydata.org/donate.html",
        "parentName": "p"
      }}>{`donating`}</a>
      {`,
or `}
      <a {...{
        "href": "https://github.com/pandas-dev/pandas",
        "parentName": "p"
      }}>{`contributing to the project itself`}</a></p>
    <br />
    <hr />
    <h2 {...{
      "id": "support-the-use-pandas-author",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#support-the-use-pandas-author",
        "aria-label": "support the use pandas author permalink",
        "className": "anchor before",
        "parentName": "h2"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Support the Use Pandas author`}</h2>
    <h3 {...{
      "id": "follow-me-on-twitter",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#follow-me-on-twitter",
        "aria-label": "follow me on twitter permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Follow me on Twitter:`}</h3>
    <p><a {...{
        "href": "https://twitter.com/KennethCassel",
        "parentName": "p"
      }}>{`https://twitter.com/KennethCassel`}</a></p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Let me know what you think about the Use Pandas project`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Hear about other things I'm doing (Mostly Python and Programmer Productivity stuff)`}</li>

    </ul>
    <h3 {...{
      "id": "check-out-my-videos",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#check-out-my-videos",
        "aria-label": "check out my videos permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Check out my videos:`}</h3>
    <p>{`I make videos about Software Careers and Programmer Productivity on my `}
      <a {...{
        "href": "https://www.youtube.com/channel/UCTSEIV0w0SjNDkt3M3_Jsvw",
        "parentName": "p"
      }}>{`youtube channel`}</a></p>
    <h3 {...{
      "id": "connect-with-me-on-linkedin",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#connect-with-me-on-linkedin",
        "aria-label": "connect with me on linkedin permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Connect with me on LinkedIn:`}</h3>
    <p><a {...{
        "href": "https://www.linkedin.com/in/kenneth-cassel/",
        "parentName": "p"
      }}>{`https://www.linkedin.com/in/kenneth-cassel/`}</a></p>
    <h3 {...{
      "id": "follow-me-on-github",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#follow-me-on-github",
        "aria-label": "follow me on github permalink",
        "className": "anchor before",
        "parentName": "h3"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Follow me on GitHub:`}</h3>
    <p><a {...{
        "href": "https://github.com/kennethcassel",
        "parentName": "p"
      }}>{`https://github.com/kennethcassel`}</a></p>
    <br />
    <br />
    <br />
    <br />
    <p>{`This project is heavily inspired by Gabe Ragland's site for React Hooks `}
      <a {...{
        "href": "https://www.usehooks.com",
        "parentName": "p"
      }}>{`usehooks`}</a>
      {`.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      